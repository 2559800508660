<template>
	<div>
		<div style="" id="identitygroup_popup_az6hmn" type="primary" @click="openIdentityGroupPopupBtn"></div>
		<a-modal class="identitygroup_popup_select_modal" :zIndex="2000" :destroyInClose="true" cancelText="取消"
			okText="保存" :centered="true" :visible="visible" @ok="handleSaveOk" @cancel="handleCancle" title="选择身份组"
			:width="800" :height="550" :destroyOnClose="true" wrapClassName="full-modal">
			<div class="identitygroup_popup_content">
				<div class="identitygroup_popup_content_left">
					<div class="identitygroup_popup_content_topBar">
						<div class="identitygroup_popup_topBar_item_box">
							<div class="identitygroup_popup_topBar_item"
								:class="{'identitygroup_popup_topBar_item_active':topType == 1}" @click="topType = 1">
								身份组</div>
							<div class="identitygroup_popup_topBar_item"
								:class="{'identitygroup_popup_topBar_item_active':topType == 2}" @click="topType = 2">部门
							</div>
						</div>
						<div class="identitygroup_popup_addbtn" @click="identitygroupDepartmentManage">
							<!-- <span v-if="topType == 1" class="mbm-iconfont mbm-jia"></span> -->
							{{topType == 1?'身份组管理':'部门管理'}}
						</div>
					</div>

					<div class="identitygroup_popup_checkbox" id="identitygroup_popup_checkbox">
						<div v-if="topType == 1" class="identitygroup_popup_department">
							<a-tree checkable :tree-data="IdentityData.child" v-model:checkedKeys="identityValue"
								:autoExpandParent="true" :checkable="true" :selectable="false"
								:replace-fields="replaceFields" :checkStrictly="true" :defaultExpandAll="true">
								<template #title="{ key: id, name, user_count}">
									<span>{{ name }}</span><span class="identitygroup_user_count"
										style="margin-left: 6px;">({{user_count}}人)</span>
								</template>
							</a-tree>
						</div>
						<div v-if="topType == 2" class="identitygroup_popup_department">
							<a-tree checkable :tree-data="DepartmentData.child" v-model:checkedKeys="DepartmentValue"
								:autoExpandParent="true" :checkable="true" :selectable="false"
								:replace-fields="replaceFields" :checkStrictly="true" :defaultExpandAll="true">
								<template #title="{ key: id, name, user_count}">
									<span>{{ name }}</span><span class="identitygroup_user_count"
										style="margin-left: 6px;">({{user_count}}人)</span>
								</template>
							</a-tree>
						</div>
						<!-- 加载动画 -->
						<loading defaultClass="mbm_loading" :loadingShow="loadingShow" :type="4" title="" 
							style="height:100%;position:absolute">
						</loading>
					</div>
				</div>
				<div class="identitygroup_popup_content_right">
					<div>
						<p class="identitygroup_popup_title">已选择的身份组</p>
						<p class="identitygroup_popup_subtitle">新加入所选组的成员，将自动获取权限</p>
					</div>

					<div class="identitygroup_popup_select_list">
						<div class="identitygroup_popup_select_item" v-for="(item,index) in resultSelectData">
							<div>
								<span class="mbm-iconfont mbm-muluguanbi identitygroup_wenjian_icon"></span>
								{{item.name}}
							</div>
							<span class="mbm-iconfont mbm-guanbi1 identitygroup_close_icon"
								@click="removeItem(index)"></span>
						</div>
					</div>
				</div>
			</div>
		</a-modal>
		<!-- 新建身份组 -->
		<a-modal class="ocm_createModal" :centered="true" v-model:visible="addIdentityModalShow" :zIndex="2100"
			:title="addIdentityModalType==1?'新建身份组':'修改身份组'" ok-text="确认" cancel-text="取消" @ok="newIdentity"
			:width="671" :destroyOnClose="true">
			<div class="sub_editMemberContent">
				<a-form :model="identityItem" :rules="identityRules">
					<div class="sub_member_title">基本信息</div>
					<div class="sub_editMemberContent_item">
						<div class="sub_editMemberContent_title" style="width:136px;"><span
								class="sub_required">*</span>身份组名称</div>
						<a-form-item ref="name" name="name">
							<a-input class="sub_editMemberContent_input" placeholder="请输入"
								v-model:value="identityItem.name">
							</a-input>
						</a-form-item>
					</div>

					<div class="sub_editMemberContent_item">
						<div class="sub_editMemberContent_title" style="margin-top:10px;padding-left: 8px;width:136px;">
							身份组说明</div>
						<a-form-item ref="explain" name="explain">
							<a-textarea class="sub_editMemberContent_input" placeholder="请输入" showCount :maxlength="30"
								v-model:value="identityItem.explain">
							</a-textarea>
						</a-form-item>
					</div>

					<div class="sub_member_title">设置</div>
					<div class="sub_editMemberContent_item">

						<a-form-item ref="set" name="set">
							<a-checkbox-group v-model:value="identityItem.set" style="width:100%;">

								<div v-for="(item,index) in identitySetOptions" class="sub_editMemberContent_item"
									style="align-items: center;margin-bottom:24px;">
									<div class="sub_editMemberContent_title" style="padding-left: 8px;width:200px;">
										{{item.name}}
									</div>
									<a-checkbox :value="item.id">开启</a-checkbox>
								</div>
							</a-checkbox-group>
						</a-form-item>
					</div>

				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {
		defineComponent,
		ref,
		watch
	} from 'vue';
	import {
		message
	} from 'ant-design-vue';
	import config from '/src/config/config.js';
	import base_func from '/src/config/base_func.js';
	import loading from '/src/components/loading.vue';

	export default {
		name: 'IdentityGroupPopup',
		components: {
			loading
		},
		props: {},
		data() {
			return {
				topType: 1, //1:身份组，2：部门
				visible: false,
				identityValue: {
					checked: []
				},
				IdentityData: [], //身份组数据
				DepartmentValue: {
					checked: []
				},
				DepartmentData: [], //部门数据
				replaceFields: {
					children: 'child',
					key: 'id',
					title: 'name',
				},
				resultSelectData: [], //最终选择结果

				//添加身份组
				addIdentityModalType: 1,
				identityItem: {
					name: '',
					explain: '',
					set: [],
				},
				identityRules: {
					name: [{
						required: true,
						message: '请填写身份名',
						trigger: 'blur'
					}],
				},
				addIdentityModalShow: false,
				identitySetOptions: [],
				hasSubPower: false,
				isHave: false,
				removeIdList: [],
				loadingShow:true,

			}
		},
		created() {
			// this.getIdentityData();
			// this.getDepartmentData();
			// this.getRules();
			watch(() => this.identityValue, val => {
				this.resultSelectDataChange();
			});
			watch(() => this.DepartmentValue, val => {
				this.resultSelectDataChange();
			});
		},
		beforeDestroy() {},
		methods: {
			// 新建和修改身份组
			newIdentity() {
				let that = this;
				let rules = '';
				if (this.identityItem.set) {
					for (let i = 0; i < this.identityItem.set.length; i++) {
						rules += this.identityItem.set[i];
						if ((i + 1) != this.identityItem.set.length) {
							rules += ',';
						}
					}
				}
				let param = {
					name: this.identityItem.name,
					rules: rules,
					desc: this.identityItem.explain,
				};
				if (this.addIdentityModalType == 2) { // 气泡框修改
					param.id = this.popoverSelectItem.id;
				} else if (this.addIdentityModalType == 3) { // 修改
					param.id = this.currentId;
				}
				that.$apiRequest.post("/department/roleEdit", param).then(res => {
					message.success(res.data.message);
					//获取身份组
					that.getIdentityData(true);
					that.identityValue.checked.push(res.data.data.id);
					that.resultSelectData.push({
						type: 1,
						id: res.data.data.id,
						name: res.data.data.name,
						total: res.data.data.user_count
					});

					that.addIdentityModalShow = false;
				}).catch(err => {

					message.error(err.data.message)
				});
			},
			// 获取权限列表
			getRules() {
				let that = this;
				this.loadingShow = true;
				that.$apiRequest.post("/department/initData", {}).then(res => {
					that.identitySetOptions = res.data.rules;
					if (res.data.permission.code == 200) {
						that.hasSubPower = true;
					} else {
						that.hasSubPower = false;
					}
					this.loadingShow = false;
				}).catch(err => {
					base_func.checkState(err.data);
				});
			},
			//新建身份组和部门管理
			identitygroupDepartmentManage() {
				if (this.topType == 1) { //新建身份组
					//this.addIdentityItem(1);
					if (config.devEnv == 'dev') {
						window.open('Identitymanagement');
					} else {
						window.open(config.memberIdentityUrl);
					}
				} else if (this.topType == 2) { //部门管理
					if (config.devEnv == 'dev') {
						window.open('submanagement');
					} else {
						window.open(config.memberDepartmentUrl);
					}

				}
			},
			/* 添加身份组 */
			addIdentityItem(type) {
				this.addIdentityModalType = type;
				let identityItem = {
					name: '',
					explain: '',
					set: [],
				};
				// if (type == 2) {
				// 	identityItem.name = this.popoverSelectItem.name;
				// 	identityItem.explain = this.popoverSelectItem.desc;
				// 	identityItem.set = this.popoverSelectItem.rules;
				// } else if (type == 3) {
				// 	identityItem.name = this.catalogueCurrent.name;
				// 	identityItem.explain = this.catalogueCurrent.desc;
				// 	identityItem.set = this.catalogueCurrent.rules;
				// }
				this.identityItem = identityItem;
				this.addIdentityModalShow = true;
			},
			//移除单个最终结果
			removeItem(index) {
				if (this.resultSelectData[index].type == 1) {
					let dataIndex = this.identityValue.checked.indexOf(this.resultSelectData[index].id, 0);
					this.identityValue.checked.splice(dataIndex, 1);
				} else if (this.resultSelectData[index].type == 2) {

					let dataIndex = this.DepartmentValue.checked.indexOf(this.resultSelectData[index].id);
					this.DepartmentValue.checked.splice(dataIndex, 1);
				}
				this.resultSelectData.splice(index, 1);
			},
			// 最终结果改变
			resultSelectDataChange() {
				this.resultSelectData = [];
				if (this.identityValue.checked != '' && this.identityValue.checked) {
					let identityList = this.identityValue.checked;
					for (let i = 0; i < identityList.length; i++) {
						this.dealTreeData(identityList[i], this.IdentityData.child, 1);
						
					}
				}
				if (this.DepartmentValue.checked != '' && this.DepartmentValue.checked) {
					let DepartmentList = this.DepartmentValue.checked;
					for (let i = 0; i < DepartmentList.length; i++) {
						this.dealTreeData(DepartmentList[i], this.DepartmentData.child, 2);
					}
				}
			},
			dealTreeData(id, treeData, type) {
				if(treeData && typeof treeData.length !='undefined'){
					for (let i = 0; i < treeData.length; i++) {
						if (id == treeData[i].id) {
							this.resultSelectData.push({
								type: type,
								id: id,
								name: treeData[i].name,
								total: treeData[i].user_count
							});
						}
						if (treeData[i].child) {
							this.dealTreeData(id, treeData[i].child, type);
						}
					}
				}
				
			},
			/*打开弹窗*/
			openIdentityGroupPopupBtn() {
				this.getIdentityData();
				this.getDepartmentData();
				this.getRules();
				this.identityValue = {
					checked: []
				};
				this.DepartmentValue = {
					checked: []
				};
				if (typeof selectMemberPermissionData != 'undefined') {
					if (selectMemberPermissionData.data != '') {
						selectMemberPermissionData.data.push({
							id: 9999,
							name: 'fsdf',
							type: 2,
							total: 100,
						});
					}
					this.resultSelectData = selectMemberPermissionData.data;
					if (selectMemberPermissionData.data != '') {
						for (let i = 0; i < selectMemberPermissionData.data.length; i++) {
							if (selectMemberPermissionData.data[i].type == 1) {
								this.identityValue.checked.push(selectMemberPermissionData.data[i].id)
							}
							if (selectMemberPermissionData.data[i].type == 2) {
								this.DepartmentValue.checked.push(selectMemberPermissionData.data[i].id)
							}
						}
					}
				}
				this.visible = true;
			},
			handleSaveOk() {
				if (typeof identityPopupSelectCallbacki1 == 'function') {
					identityPopupSelectCallbacki1(JSON.parse(JSON.stringify(this.resultSelectData)))
				}
				// selectMemberPermissionData.data = JSON.parse(JSON.stringify(this.resultSelectData))
				this.visible = false;
			},
			/*关闭弹窗*/
			handleCancle() {
				this.visible = false;
			},
			// 身份组多选
			identityChange(e) {
				for (let i = 0; i < e.length; i++) {
					e[i].type = this.topType;
				}
				this.identityValue = e;
			},
			//获取身份组信息
			getIdentityData(ifToBottom) {
				this.loadingShow = true;
				let that = this;
				that.$apiRequest.post("/department/role", {}).then(res => {
					that.IdentityData = res.data.data;
					let newArr = [{
						id: that.IdentityData.id,
						name: that.IdentityData.name,
						user_count: that.IdentityData.user_count
					}]
					that.IdentityData.child = newArr.concat(that.IdentityData.child);
					if (ifToBottom == true) {
						this.$nextTick(function() {
							var div = document.getElementById('identitygroup_popup_checkbox');
							div.scrollTop = div.scrollHeight;
						})
					}
					this.loadingShow = false;
				}).catch(err => {
					base_func.checkState(err.data);
				});
			},
			//获取部门信息
			getDepartmentData() {
				let that = this;
				this.loadingShow = true;
				that.$apiRequest.post("/department/list", {}).then(res => {
					that.DepartmentData = res.data.data;
					this.loadingShow = false;
				}).catch(err => {
					base_func.checkState(err.data);
				});
			},

		},
	}
</script>

<style>
	.identitygroup_popup_select_modal .ant-modal-body {
		padding: 0;
	}

	.identitygroup_popup_content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-start;
	}

	.identitygroup_popup_content_left {
		width: 440px;
		border-right: 1px solid #e7e7e7;
	}

	.identitygroup_popup_content_topBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 26px 0 36px;

		border-bottom: 1px solid #e7e7e7;
	}

	.identitygroup_popup_topBar_item_box {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.identitygroup_popup_topBar_item {
		padding: 12px 0;
		margin-right: 30px;
		cursor: pointer;
		border-bottom: 2px solid transparent;
		margin-bottom: -1px;
	}

	.identitygroup_popup_topBar_item:hover {
		border-color: #09bd71;
		color: #09bd71;
	}

	.identitygroup_popup_topBar_item_active {
		border-color: #09bd71;
		color: #09bd71;
	}

	.identitygroup_popup_addbtn {
		font-size: 14px;
		color: #09bd71;
		cursor: pointer;
	}

	.identitygroup_popup_addbtn>span {
		font-size: 14px;
		margin-right: 4px;
	}

	.identitygroup_popup_addbtn:hover {
		opacity: 0.7;
	}


	.identitygroup_popup_checkbox {
		position:relative;
		height: 380px;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 20px 40px;
		box-sizing: border-box;
	}

	.identitygroup_popup_checkbox_item {
		width: 100%;
		padding: 12px 0;
		cursor: pointer;
	}

	.identitygroup_popup_checkbox_item:hover {
		background: rgba(9, 189, 113, 0.2);
	}

	.identitygroup_popup_department .ant-tree li {
		padding: 8px 0 !important;
	}

	.identitygroup_popup_department .ant-tree li ul {
		padding-top: 8px;
		padding-left: 30px;
	}

	.identitygroup_popup_department .ant-tree-node-content-wrapper {
		width: 80%;
	}

	.identitygroup_popup_department .ant-tree li .ant-tree-node-content-wrapper:hover {
		background-color: transparent;
	}

	.identitygroup_popup_department .ant-tree li .ant-tree-node-content-wrapper .ant-tree-title {
		position: relative;
		z-index: 2;
	}

	.identitygroup_popup_department .ant-tree li .ant-tree-switcher {
		position: relative;
		z-index: 2;
	}

	.identitygroup_popup_department .ant-tree li .ant-tree-checkbox {
		position: relative;
		z-index: 2;
	}

	.identitygroup_popup_department .ant-tree li .ant-tree-node-content-wrapper {
		position: relative;
		z-index: 1;
	}

	.identitygroup_popup_department .ant-tree li .ant-tree-node-content-wrapper:hover:before {
		content: "";
		position: absolute;
		width: 800px;
		height: 40px;
		top: -8px;
		left: -200px;
		background-color: rgba(9, 189, 113, 0.2);
	}

	.identitygroup_user_count {
		color: #09bd71;
	}

	.identitygroup_popup_content_right {
		flex: 1;
		height: 448px;
		padding: 34px 24px;
		box-sizing: border-box;
		align-items: flex-start;
	}

	.identitygroup_popup_title {
		font-size: 14px;
		color: #333333;
		margin-bottom: 4px;
	}

	.identitygroup_popup_subtitle {
		font-size: 12px;
		color: #8D8D8D;
		margin: 0;
	}

	.identitygroup_popup_select_list {
		height: calc(100% - 45px);
		overflow: auto;
	}

	.identitygroup_popup_select_item {
		padding: 10px 0;
		padding-right: 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.identitygroup_wenjian_icon {
		color: #09bd71;
		margin-right: 8px;
	}

	.identitygroup_close_icon {
		cursor: pointer;
		font-size: 14px;
		color: #BFBFBF;
	}

	.identitygroup_close_icon:hover {
		color: #8C8C8C;
	}


	.sub_editMemberContent {
		padding: 24px 64px;
		box-sizing: border-box;

	}

	.sub_member_title {
		font-size: 14px;
		font-weight: bold;
		color: #333333;
		margin-bottom: 24px;
		line-height: 1;
	}

	.sub_editMemberContent_item {
		display: flex;
		align-items: flex-start;
	}

	.sub_addMembers {
		padding: 4px 8px;
		cursor: pointer;
		color: #09bd71;
	}

	.sub_addMembers:hover {
		opacity: 0.7;
	}

	.sub_editMemberContent_title {
		font-size: 14px;
		width: 20%;
		line-height: 32px;
	}

	.sub_editMemberContent_item .sub_editMemberContent_input {
		width: 100%;
	}

	.sub_editMemberContent_item .ant-form-item {
		width: 75%;
	}

	.sub_required {
		color: red;
		line-height: 1;
		margin-right: 2px;
	}
</style>
